<template>
  <rs-side-panel ref="sidePanel" v-if="invoiceList">
    <template slot="header">Toplu Fatura Kapatma</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col sm="4">
            <rs-money-field :value="totalAmount" :disabled="true" />
          </v-col>
          <v-col sm="4">
            <rs-select-collecting
              label="Ödeme Yeri"
              :bank-account-id.sync="formData.bank_account_id"
              :safe-id.sync="formData.safe_id"
              :cluster-id="cluster_id"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              can-pay
              is-active
              required
            />
          </v-col>
          <v-col sm="4">
            <rs-datepicker
              label="Tarih"
              v-model="formData.collected_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>

          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
              :rules="[rules.maxLength(formData.description, 1000)]"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasForm],
  computed: {
    totalAmount() {
      let total = 0;

      for (const index in this.invoiceList) {
        total += this.invoiceList[index].amount_to_pay * 100;
      }

      return total / 100;
    },
  },
  data() {
    return {
      invoiceList: null,
    };
  },
  methods: {
    show(invoiceList) {
      this.invoiceList = invoiceList;
      this.clusterId = this.invoiceList[0].cluster_id;

      this.formData = {
        safe_id: null,
        bank_account_id: null,
        description: null,
        collected_on: new Date().toJSON().split("T")[0],
      };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.invoiceList = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);
      formData.invoice_ids = this.invoiceList.map((item) => item.id);

      this.$api
        .post("expense/invoices/bulk-close", formData)
        .then(() => {
          this.$toast.success("Faturalar kapatıldı");
          this.$emit("saved", this.formData);
          if (event && event.closeOnSave) {
            this.hide();
            return;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
