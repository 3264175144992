<template>
  <v-card>
    <v-card-text v-if="companyId || clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Faturalar"
            icon="mdi-timeline-clock"
            :add-route="
              can('edit-invoice') ? { name: 'expenses.invoice.create' } : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            :hide-edit="clusterId && !can('edit-invoice')"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            :show-delete="clusterId && can('delete-invoice')"
            :delete-enabled="deleteEnabled"
            @click:delete="handleDeleteClick"
            v-bind="titleBarAttrs"
          >
            <template v-slot:buttons:prepend>
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="Tarih Filtrele"
                :items="dateFilterList"
                v-model="lastPaymentFilter"
                @change="handleDateFilterChange"
                v-if="!hideDateFilter"
                dense
              />

              <v-btn
                v-if="
                  selectedItems.length &&
                  allItemsSameCluster &&
                  (!clusterId || can('edit-invoice'))
                "
                class="me-2 float-end"
                color="pink"
                outlined
                dark
                small
                @click.prevent="handleBulkCloseClick"
                title="Toplu Fatura Kapat"
              >
                Fatura Kapat
              </v-btn>

              <v-btn
                v-if="can('see-cluster-provider')"
                class="me-2 float-end"
                color="pink"
                outlined
                dark
                small
                :to="{ name: 'definitions.cluster-providers.list' }"
              >
                Tedarikçiler
              </v-btn>

              <v-btn
                v-if="
                  can('print') &&
                  selectedItems.length === 1 &&
                  (!clusterId || can('see-invoice')) &&
                  $refs.invoiceReceiptForm
                "
                class="me-2 float-end"
                color="pink"
                outlined
                dark
                small
                @click="$refs.invoiceReceiptForm.show(selectedItems[0].id)"
              >
                Mahsup Fişi Yazdır
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'expenses.invoices.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.cluster="{ item, value }">
          <router-link
            title="Toplu Yaşam Alanı Tanımına Git"
            v-if="item.cluster_id"
            :to="{
              name: 'definitions.clusters.edit',
              params: { id: item.cluster_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.provider="{ item, value }">
          <router-link
            title="Tedarikçi Tanımına Git"
            v-if="item.provider_id"
            :to="{
              name: 'definitions.cluster-providers.edit',
              params: { id: item.provider_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.billed_on="{ item, value }">
          <router-link
            :to="{
              name: 'expenses.invoices.show',
              params: { id: item.id },
            }"
          >
            <rs-table-cell-date :value="value" />
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.last_payment_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.document_no="{ value }">
          <rs-table-cell-number :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_remaining="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_to_pay="{ value }">
          <strong><rs-table-cell-number price :value="value" /></strong>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.invoiced_type_id="{ item }">
          {{ item.invoiced_type }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_collected="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.has_installments="{ item }">
          {{ item.has_installments ? "Taksitli" : "Tek Ödeme" }}
          <template
            v-if="
              item.has_installments && item.amount !== item.installments_total
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="red" dark v-bind="attrs" v-on="on">
                  mdi-exclamation
                </v-icon>
              </template>
              Taksitler toplamı, fatura kalemleri toplamıyla uyuşmuyor.
            </v-tooltip>
          </template>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ item, value }">
          <router-link
            :to="{ name: 'expenses.invoices.edit', params: { id: item.id } }"
            title="Detay Göster"
          >
            <rs-table-cell-string :limit="20" :value="value" />
          </router-link>
        </template>
      </v-data-table>

      <BulkCloseForm ref="bulkCloseForm" @saved="loadList" />
      <InvoiceReceiptForm ref="invoiceReceiptForm" />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir yönetim şirketi veya TYA seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import BulkCloseForm from "../forms/BulkCloseForm";
import InvoiceReceiptForm from "../forms/InvoiceReceiptForm";

export default {
  name: "InvoiceList",
  mixins: [filtersToURL, hasExport, hasPermissions],
  components: {
    BulkCloseForm,
    InvoiceReceiptForm,
  },
  props: {
    hideDateFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "bankList",
      "clusterId",
      "companyId",
      "company",
      "companyClustersActive",
      "invoicedTypeList",
    ]),
    allItemsSameCluster() {
      if (!this.selectedItems.length) {
        return false;
      }

      let firstClusterId = this.selectedItems[0].cluster_id;

      for (const index in this.selectedItems) {
        if (this.selectedItems[index].cluster_id !== firstClusterId) {
          return false;
        }
      }

      return true;
    },
    deleteEnabled() {
      if (!this.selectedItems.length) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (this.selectedItems[index].amount_collected > 0) {
          return false;
        }
      }

      return true;
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    companyId() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    return {
      lastPaymentFilter: null,
      search: {
        amount_remaining: {
          min: 0.01,
        },
      },
      dateFilterList: [
        {
          id: null,
          name: "Tümü",
        },
        {
          id: 1,
          name: "7 gün içinde ödenecekler",
        },
        {
          id: 2,
          name: "15 gün içinde ödenecekler",
        },
        {
          id: 3,
          name: "Ödeme tarihi geçmişler",
        },
      ],
      options: {
        sortBy: ["last_payment_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => this.clusterId && !this.can("edit-invoice"),
        },
        // {
        //   text: "TYA",
        //   value: "cluster",
        //   searchable: "multiselect",
        //   options: () => this.companyClustersActive,
        // },
        {
          text: "Tedarikçi",
          value: "provider",
          searchable: "text",
        },
        {
          text: "Fatura Tarihi",
          value: "billed_on",
          searchable: "date",
        },
        {
          text: "Ödeme Tarihi",
          value: "last_payment_on",
          searchable: "date",
        },
        {
          text: "Ödeme Şekli",
          value: "has_installments",
          searchable: "select",
          options: () => [
            { id: false, name: "Tek Ödeme" },
            { id: true, name: "Taksitli" },
          ],
        },
        {
          text: "Belge No (Fatura-Fiş No)",
          value: "invoice_no",
          searchable: "text",
          width: "140px",
        },
        {
          text: "Evrak No",
          value: "document_no",
          searchable: "text",
        },
        {
          text: "Fatura Tipi",
          value: "invoiced_type_id",
          searchable: "multiselect",
          options: () => this.invoicedTypeList,
        },
        {
          text: "Ödenecek Tutar",
          value: "amount_to_pay",
          searchable: "number",
          align: "end",
        },
        {
          text: "Toplam Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Toplam Kalan Tutar",
          value: "amount_remaining",
          searchable: "number",
          align: "end",
          width: "120px",
        },
        {
          text: "Ödenen Tutar",
          value: "amount_collected",
          searchable: "number",
          align: "end",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Banka",
          value: "bank_1",
          searchable: "multiselect",
          options: () => this.bankList,
        },
        {
          text: "IBAN",
          value: "bank_1_iban",
          searchable: "text",
        },
      ],
      titleBarAttrs: {
        // exportColumns: {
        //   cluster: "TYA",
        //   provider: "Tedarikçi",
        //   billed_on: "Fatura Tarihi",
        // },
        exportUrl: "expense/invoices",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      // params.amount_remaining = {
      //   min: 0.01,
      // };

      if (this.clusterId) {
        params.cluster = [this.clusterId];
      }

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      return params;
    },
    loadList() {
      if (this.isLoading || (!this.companyId && !this.clusterId)) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      const params = this.getParams();

      this.$api
        .query("expense/invoices", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "expenses.invoices.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
    handleBulkCloseClick() {
      if (!this.clusterId) {
        this.$toast.warning(
          "Bu özelliği kullanabilmeniz için TYA seçili olmalıdır",
          { timeout: 5000 }
        );
        return;
      }
      this.$refs.bulkCloseForm.show(this.selectedItems);
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} tane faturayı silmek istediğinizden emin misiniz?`
      );
    },
    handleDeleteConfirmed() {
      if (!this.selectedItems.length || this.isLoading) {
        return false;
      }

      const selectedItemIds = this.selectedItems.map((item) => item.id);
      const promises = [];

      this.isLoading = true;

      for (const index in selectedItemIds) {
        const promise = this.delete(selectedItemIds[index]);
        promises.push(promise);
      }

      Promise.all(promises).finally(() => {
        this.isLoading = false;
        this.$refs.confirmDelete.hide();
        this.loadList();
      });
    },
    delete(id) {
      return this.$api
        .delete(`expense/invoices/${id}`)
        .then(() => {
          this.$toast.success("Ödeme silindi");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleDateFilterChange(value) {
      const t = new Date();

      if (this.search.last_payment_on === undefined) {
        this.search.last_payment_on = {
          min: undefined,
          max: undefined,
        };
      }

      if (value === null) {
        this.search.last_payment_on = {
          min: undefined,
          max: undefined,
        };
      } else if (value === 1) {
        // payment in 7 days
        this.search.last_payment_on = {
          min: t,
          max: new Date(t.getFullYear(), t.getMonth(), t.getDate() + 7),
        };
      } else if (value === 2) {
        // payment in 7 days
        this.search.last_payment_on.min = t;
        this.search.last_payment_on.max = new Date(
          t.getFullYear(),
          t.getMonth(),
          t.getDate() + 15
        );
      } else if (value === 3) {
        // payment in 7 days
        this.search.last_payment_on.min = undefined;
        this.search.last_payment_on.max = new Date(
          t.getFullYear(),
          t.getMonth(),
          t.getDate() + 1
        );
      }

      this.loadList();
    },
  },
};
</script>
