var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.companyId || _vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Faturalar","icon":"mdi-timeline-clock","add-route":_vm.can('edit-invoice') ? { name: 'expenses.invoice.create' } : null,"edit-enabled":_vm.selectedItems.length === 1,"hide-edit":_vm.clusterId && !_vm.can('edit-invoice'),"search":_vm.search.query,"show-delete":_vm.clusterId && _vm.can('delete-invoice'),"delete-enabled":_vm.deleteEnabled},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:delete":_vm.handleDeleteClick},scopedSlots:_vm._u([{key:"buttons:prepend",fn:function(){return [(!_vm.hideDateFilter)?_c('rs-select',{staticClass:"float-end me-2",staticStyle:{"max-width":"150px"},attrs:{"label":"Tarih Filtrele","items":_vm.dateFilterList,"dense":""},on:{"change":_vm.handleDateFilterChange},model:{value:(_vm.lastPaymentFilter),callback:function ($$v) {_vm.lastPaymentFilter=$$v},expression:"lastPaymentFilter"}}):_vm._e(),(
                _vm.selectedItems.length &&
                _vm.allItemsSameCluster &&
                (!_vm.clusterId || _vm.can('edit-invoice'))
              )?_c('v-btn',{staticClass:"me-2 float-end",attrs:{"color":"pink","outlined":"","dark":"","small":"","title":"Toplu Fatura Kapat"},on:{"click":function($event){$event.preventDefault();return _vm.handleBulkCloseClick.apply(null, arguments)}}},[_vm._v(" Fatura Kapat ")]):_vm._e(),(_vm.can('see-cluster-provider'))?_c('v-btn',{staticClass:"me-2 float-end",attrs:{"color":"pink","outlined":"","dark":"","small":"","to":{ name: 'definitions.cluster-providers.list' }}},[_vm._v(" Tedarikçiler ")]):_vm._e(),(
                _vm.can('print') &&
                _vm.selectedItems.length === 1 &&
                (!_vm.clusterId || _vm.can('see-invoice')) &&
                _vm.$refs.invoiceReceiptForm
              )?_c('v-btn',{staticClass:"me-2 float-end",attrs:{"color":"pink","outlined":"","dark":"","small":""},on:{"click":function($event){return _vm.$refs.invoiceReceiptForm.show(_vm.selectedItems[0].id)}}},[_vm._v(" Mahsup Fişi Yazdır "),_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e()]},proxy:true}],null,false,851796302)},'rs-table-title-bar',_vm.titleBarAttrs,false)):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"body.append",fn:function({ headers }){return [(_vm.list.length > 0)?_c('rs-table-foot-totals',{attrs:{"headers":headers,"totals":_vm.footTotals}}):_vm._e()]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'expenses.invoices.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.cluster",fn:function({ item, value }){return [(item.cluster_id)?_c('router-link',{attrs:{"title":"Toplu Yaşam Alanı Tanımına Git","to":{
            name: 'definitions.clusters.edit',
            params: { id: item.cluster_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"item.provider",fn:function({ item, value }){return [(item.provider_id)?_c('router-link',{attrs:{"title":"Tedarikçi Tanımına Git","to":{
            name: 'definitions.cluster-providers.edit',
            params: { id: item.provider_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"item.billed_on",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'expenses.invoices.show',
            params: { id: item.id },
          }}},[_c('rs-table-cell-date',{attrs:{"value":value}})],1)]}},{key:"item.last_payment_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.document_no",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"value":value}})]}},{key:"item.amount_remaining",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.amount_to_pay",fn:function({ value }){return [_c('strong',[_c('rs-table-cell-number',{attrs:{"price":"","value":value}})],1)]}},{key:"item.invoiced_type_id",fn:function({ item }){return [_vm._v(" "+_vm._s(item.invoiced_type)+" ")]}},{key:"item.amount_collected",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.has_installments",fn:function({ item }){return [_vm._v(" "+_vm._s(item.has_installments ? "Taksitli" : "Tek Ödeme")+" "),(
            item.has_installments && item.amount !== item.installments_total
          )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-exclamation ")])]}}],null,true)},[_vm._v(" Taksitler toplamı, fatura kalemleri toplamıyla uyuşmuyor. ")])]:_vm._e()]}},{key:"item.description",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{ name: 'expenses.invoices.edit', params: { id: item.id } },"title":"Detay Göster"}},[_c('rs-table-cell-string',{attrs:{"limit":20,"value":value}})],1)]}}],null,false,1767865373),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false)),_c('BulkCloseForm',{ref:"bulkCloseForm",on:{"saved":_vm.loadList}}),_c('InvoiceReceiptForm',{ref:"invoiceReceiptForm"}),_c('rs-confirm',{ref:"confirmDelete",attrs:{"loading":_vm.isLoading},on:{"confirmed":_vm.handleDeleteConfirmed,"cancelled":function($event){return _vm.$refs.confirmDelete.hide()}}})],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir yönetim şirketi veya TYA seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }